import { LOCALES } from "../locales";

export default {
  [LOCALES.SPANISH]: {
    hello: "Hola",
    ["Tools"]: "",
    ["Administration Tools"]: "",
    ["Application Permission"]: "",
    ["Report Permissions"]: "",
    ["Client Data Maps"]: "",
    ["Client Status"]: "",
    ["Create New Client"]: "",
    ["3rd Party Diamond Imports"]: "",
    ["SOLR Core Client"]: "",
    ["Vendor Rating"]: "",
    ["Limits"]: "",
    ["Legacy"]: "",
    ["Console Upload"]: "",
    ["Open Source Mapping"]: "",
    ["Pending Syncs"]: "",
    ["Transfer ZZ.Gemfind"]: "",
    ["Login Preferences"]: "",
    ["Retailer Locator"]: "",
    ["Block Designers For Demo"]: "",
    ["Settings"]: "",
    ["Permissions"]: "",
    ["Email Template"]: "",
    ["Data Maps"]: "",
    ["Metal Market"]: "",
    ["Trade Shows"]: "",
    ["Add Color"]: "",
    ["Api Access Token"]: "",
    ["Campaigns"]: "",
    ["Mailing List"]: "",
    ["Compose"]: "",
    ["Report"]: "",
    ["Global Events"]: "",
    ["Syncs"]: "",
    ["Tools Reports"]: "",
    ["Clients Reports"]: "",
    ["Permission Reports"]: "",
    ["Data Related"]: "",
    ["Jewelry Log Reports"]: "",
    ["Diamond Report"]: "",
    ["Diamond Clicks"]: "",
    ["Extended Diamond Chart"]: "",
    ["Diamond Clarity Report"]: "",
    ["Diamond Color Report"]: "",
    ["Diamond Cut Report"]: "",
    ["Diamond Shape Report"]: "",
    ["Diamond Certificate Report"]: "",
    ["Diamond Size Report"]: "",
    ["Jewelry Report"]: "",
    ["Jewelry Chart Report"]: "",
    ["Jewelry Category Report"]: "",
    ["Jewelry Metal Type Report"]: "",
    ["Jewelry Price Report"]: "",
    ["Jewelry Retailer Clicks Report"]: "",
    ["Jewelry Vendor Clicks Report"]: "",
    ["Catalog"]: "",
    ["Diamond"]: "",
    ["Mined Diamond"]: "",
    ["Color Diamond"]: "",
    ["Lab Diamond"]: "",
    ["Upload History"]: "",
    ["Add Individual Diamond"]: "",
    ["Add Diamond"]: "",
    ["Upload Diamond File"]: "",
    ["Diamond Mapping"]: "",
    ["Manage FTP Credentials"]: "",
    ["Download Product File"]: "",
    ["Jewelry"]: "",
    ["Catalog List"]: "",
    ["View My Product"]: "",
    ["Add Product"]: "",
    ["Edit Product"]: "",
    ["Upload Product File"]: "",
    ["Manage Collection"]: "",
    ["Product Markup"]: "",
    ["Add Collection"]: "",
    ["Data Mapping"]: "",
    ["Essential"]: "",
    ["Item Identity"]: "",
    ["Configurable Products"]: "",
    ["Item Details"]: "",
    ["Item Pricing"]: "",
    ["Relational Pricing"]: "",
    ["Cost Basis Pricing"]: "",
    ["Item-Promo Specials"]: "",
    ["Item Media"]: "",
    ["Item Sizing"]: "",
    ["Gemstone Details"]: "",
    ["Semi-Mounts & Ring Builder"]: "",
    ["Watch Details"]: "",
    ["Additional Details"]: "",
    ["Purchase Order"]: "",
    ["Purchase Order History"]: "",
    ["Update Purchase Order"]: "",
    ["Marketing"]: "",
    ["Upload Marketing Material"]: "",
    ["Appointment"]: "",
    ["Apps"]: "",
    ["Diamond Links"]: "",
    ["Application Preview"]: "",
    ["Dealers"]: "",
    ["Mined Diamond Markup"]: "",
    ["Fancy Color Markup"]: "",
    ["Lab Grown Markup"]: "",
    ["Available Filter Settings"]: "",
    ["Initial Filter Settings"]: "",
    ["Options"]: "",
    ["Shipping"]: "",
    ["Navigation"]: "",
    ["Shopping Cart Integration"]: "",
    ["Shopping Cart Configuration"]: "",
    ["Api Endpoint And Authentication"]: "",
    ["Ring Builder"]: "",
    ["Vendors"]: "",
    ["Archived Vendors"]: "",
    ["Manage Vendors"]: "",
    ["Diamond Markup"]: "",
    ["Setting Markups"]: "",
    ["Master Link"]: "",
    ["E-mail Templates"]: "",
    ["Stud Builder"]: "",
    ["Shopping Cart Integrations"]: "",
    ["Setting Markup"]: "",
    ["Website Sync"]: "",
    ["Sync History"]: "",
    ["OutBound Mapping"]: "",
    ["FTP Details"]: "",
    ["Watch Customizer"]: "",
    ["Time Pieces"]: "",
    ["Pendant Builder"]: "",
    ["Search Feature"]: "",
    ["Diamond Search"]: "",
    ["Color Diamond Search"]: "",
    ["Lab Diamond Search"]: "",
    ["Watches Search"]: "",
    ["Jewelry Search"]: "",
    ["Community"]: "",
    ["Vendor Manager"]: "",
    ["My Vendors"]: "",
    ["Network"]: "",
    ["Out Of Network"]: "",
    ["New"]: "",
    ["Recently Updated"]: "",
    ["Invite Your Vendors"]: "",
    ["Popular"]: "",
    ["Archived"]: "",
    ["Pending Requests"]: "",
    ["Retailer"]: "",
    ["Jewelry Data & Permissions"]: "",
    ["Pending Jewelry Request"]: "",
    ["Diamond Data & Permission"]: "",
    ["Pending Diamond Request"]: "",
    ["Ring Builder Retailer & Permission"]: "",
    ["Pending Ring Builder Request"]: "",
    ["Retailer Profile"]: "",
    ["Jewelry Chart Reports"]: "",
    ["Help"]: "",
    ["Report An Issue"]: "",
    ["Suggest A New Feature"]: "",
    ["Watch Training Videos"]: "",

    ["Edit Profile"]: "",
    ["Manage User"]: "",
    ["Manage Account"]: "",
    ["Manage Language"]: "",
    ["Manage Currency"]: "",
    ["Logout"]: "",
    ["Top Menu"]: "",
    ["Dashboard"]: "",
    ["Contact"]: "",
    ["Contacts"]: "",
    ["Admin"]: "",

    ["Conversations"]: "",
    ["Terms of Service"]: "",
    ["Privacy Statement"]: "",
    ["Copyright 2021 GemFind"]: "",

    ["-Server Up-Time: Uptime info not available - please try reloading this page"]:
      "",
    ["JewelCloud Speed Test"]: "",
    ["-RapNet Prices Updated:"]: "",
    ["Client Tools"]: "",
    ["Select Client Type"]: "",
    ["Client Name"]: "",
    ["Selected Client Tools"]: "",
    ["Name"]: "",
    ["Phone"]: "",
    ["Type"]: "",
    ["Verified"]: "",
    ["Approved Apps"]: "",
    ["Users"]: "",
    ["Username"]: "",
    ["Password"]: "",
    ["Edit"]: "",
    ["View Profile"]: "",
    ["Download Inventory"]: "",
    ["Impersonate JewelCloud"]: "",
    ["Return to JewelCloud as Dealer 720"]: "",
    ["Updated"]: "",
    ["Data Rating"]: "",
    ["Location"]: "",
    ["Active"]: "",
    ["Product Loves"]: "",
    ["Collections"]: "",
    ["Items"]: "",
    ["Contact Name"]: "",
    ["Vendor tags"]: "",
    ["No tag to display"]: "",
    ["Brands"]: "",
    ["Reset Password"]: "",
    ["User Name"]: "",
    ["Confirm Password"]: "",

    ["App Activation Control"]: "",
    ["Select Dealer"]: "",
    ["Diamond Link"]: "",
    ["Diamond Search Links"]: "",
    ["DS Ecommerce"]: "",
    ["DS SSL"]: "",
    ["DS Show PoweredBy"]: "",
    ["DS LabGrownDiamonds"]: "",
    ["Jewel Cloud"]: "",
    ["API"]: "",
    ["Direct feed"]: "",
    ["Windows App"]: "",
    ["ML Legacy"]: "",
    ["ML2"]: "",
    ["ML3"]: "",
    ["ML Ecommerce"]: "",
    ["ML SSL"]: "",
    ["ML Show PoweredBy"]: "",
    ["RB Ecommerce"]: "",
    ["RB SSL"]: "",
    ["RB Show PoweredBy"]: "",
    ["Tryon"]: "",
    ["SB Ecommerce"]: "",
    ["SB SSL"]: "",
    ["SB Show PoweredBy"]: "",
    ["PB Ecommerce"]: "",
    ["PB SSL"]: "",
    ["PB Show PoweredBy"]: "",
    ["Gemfind.TV"]: "",
    ["Purchase Order(PO)"]: "",
    ["Bridal/Fashion Separation"]: "",
    ["Diamond Group Discount"]: "",
    ["POS System"]: "",
    ["Submit"]: "",

    ["Reports Permissions"]: "",
    ["All"]: "",
    ["Diamond Dealers"]: "",
    ["Retailers"]: "",
    ["Diamond Reports"]: "",
    ["Jewelry Reports"]: "",
    ["Global Diamond Reports"]: "",
    ["Global Jewelry Reports"]: "",

    ["Client Data Mapping"]: "",
    ["Dealer ID"]: "",
    ["Dealer Company"]: "",
    ["Website Address"]: "",
    ["Upload"]: "",
    ["InBound Data Map"]: "",
    ["Outgoing Data Map"]: "",
    ["OutBound Sync"]: "",
    ["Set Default Mapping"]: "",

    ["Search"]: "",
    ["Reset"]: "",
    ["InActive"]: "",
    ["Permission Type"]: "",
    ["Account Type"]: "",
    ["VendorDiamond"]: "",
    ["Vendor"]: "",
    ["Vendor Status"]: "",
    ["Profile Only"]: "",
    ["Retailer Status"]: "",
    ["Product Type"]: "",
    ["Action"]: "",
    ["Cancel"]: "",
    ["Save"]: "",
    ["Update Client Status Information"]: "",
    ["Update Date on which Client Name is Created"]: "",

    ["Add New Client"]: "",
    ["Company Name"]: "",
    ["Street Address"]: "",
    ["City"]: "",
    ["State"]: "",
    ["Zip Code"]: "",
    ["Country"]: "",
    ["Email Address"]: "",
    ["Phone Number"]: "",
    ["Generate Password"]: "",
    ["Create Client"]: "",

    ["Enable 3rd Party Diamond Imports"]: "",
    ["Dealer Name"]: "",
    ["Enabled"]: "",

    ["Add New Retailer To Core"]: "",
    ["Select Core"]: "",
    ["Enter Retailer ID"]: "",
    ["Add"]: "",
    ["id"]: "",
    ["Core Name"]: "",
    ["Retailer ID"]: "",
    ["Retailer Name"]: "",

    ["Vendor Ratings"]: "",
    ["Vendor ID"]: "",
    ["Vendor Name"]: "",
    ["System Ratings"]: "",
    ["Manual Ratings"]: "",
    ["Modified Date"]: "",
    ["Save Vendor Rating"]: "",

    ["Limit Retailer To Diamond Dealer"]: "",
    ["Limit Diamond Dealer To Retailer"]: "",
    ["Limit Retailer To Vendor"]: "",
    ["Limit Vendor To Retailer"]: "",
    ["Apply Filter"]: "",
    ["Rules"]: "",
    ["Selected"]: "",
    ["Delete"]: "",
    ["Create"]: "",
    ["Download"]: "",
    ["Rule Type"]: "",
    ["Vendor/Dealer"]: "",
    ["Created Date"]: "",
    ["Next"]: "",
    ["Diamond Dealer"]: "",
    ["Are you sure to delete this Rule"]: "",
    ["THIS CAN NOT BE UNDONE"]: "",
    ["Delete Limit Rule"]: "",
    ["Dealer Markup"]: "",

    ["(Database administration tools for DealerID 720 (Alex) & 712 (Morgan))"]:
      "",
    ["Add a Dealer to all GFLinks:"]: "",
    ["Select the DealerID you want to"]: "",
    ["to all GFLinks"]: "",
    ["Remove a Dealer from all GFLinks:"]: "",
    ["Remove"]: "",
    ["Add a Dealer to all GFLinks Lab Diamonds:"]: "",
    ["to all GFLinks Lab Diamonds"]: "",
    ["Remove a Dealer from all GFLinks Lab Diamonds:"]: "",
    ["Add DealerID"]: "",
    ["Remove a Dealer from all GFLinks: Select the DealerID you want to remove to all GFLinks."]:
      "",
    ["Remove DealerID"]: "",
    ["Add a Dealer to all GFLinks Lab Diamonds: Select the DealerID you want to add to all GFLinks Lab Diamonds."]:
      "",
    ["Remove a Dealer from all GFLinks Lab Diamonds: Select the DealerID you want to remove to all GFLinks Lab Diamonds."]:
      "",

    ["Add Console Upload Dealer Data"]: "",
    ["Create FTP"]: "",
    ["Dealer"]: "",
    ["FTP User Name"]: "",
    ["FTP Password"]: "",
    ["File Type"]: "",
    ["Path"]: "",
    ["Add Update"]: "",
    ["Console Upload Info"]: "",
    ["Console Upload Edit Info"]: "",
    ["Enter User Name"]: "",
    ["Enter Password"]: "",
    ["Enter Folder Name"]: "",
    ["Are you sure you want to delete"]: "",
    ["Dealer Data"]: "",
    ["Dealer Mapping"]: "",

    ["Magento Mapping"]: "",
    ["API URL"]: "",
    ["API Key"]: "",
    ["IsActive"]: "",
    ["Edit Megento Mapping Info"]: "",
    ["Edit Open Source Mapping Info"]: "",
    ["Port No"]: "",
    ["Delete Dealer Mapping"]: "",
    ["Megento Mapping Info"]: "",
    ["Open Source Mapping Info"]: "",

    ["Discard All"]: "",
    ["Run Valid Image Application"]: "",
    ["Sync ID"]: "",
    ["Status"]: "",
    ["Discard"]: "",

    ["Source JC ID"]: "",
    ["Destination JC ID"]: "",
    ["Replace All"]: "",
    ["Add & Update"]: "",
    ["Transfer"]: "",
    ["Are you sure you want to move data from"]: "",
    ["account to"]: "",
    ["account ? - You have select"]: "",
    ["Option"]: "",

    ["Select Designer for New"]: "",
    ["Note"]: "",
    ["This will feature a specific vendor for the"]: "",
    ["New to JewelCloud"]: "",
    ["section on the login page"]: "",
    ["Upload Logo Image for Designers"]: "",
    ["Choose Image"]: "",
    ["No File Choosen"]: "",
    ["Pixels"]: "",
    ["Png Format"]: "",
    ["Select Designer"]: "",
    ["Upload Image"]: "",
    ["Select Designer To Show on Login Page"]: "",

    ["Admin Preferences"]: "",
    ["MasterLink Theme"]: "",
    ["Default Theme"]: "",
    ["Retailer Locater Black Theme"]: "",

    ["Block Designers For Demo Retailer"]: "",
    ["Designer Name"]: "",
    ["Is Blocked ?"]: "",
    ["Update Selection"]: "",

    ["Add New"]: "",
    ["Page Number"]: "",
    ["1 Tier PageName"]: "",
    ["2 Tier PageName"]: "",
    ["3 Tier PageName"]: "",
    ["Vendor Super"]: "",
    ["Vendor Admin"]: "",
    ["Vendor User"]: "",
    ["Vendor-Diamond Super"]: "",
    ["Vendor-Diamond Admin"]: "",
    ["Vendor-Diamond User"]: "",
    ["Retailer Super"]: "",
    ["Retailer Admin"]: "",
    ["Retailer User"]: "",
    ["Admin Super"]: "",
    ["Admin Admin"]: "",
    ["Admin User"]: "",
    ["Combined Super"]: "",
    ["Combined Admin"]: "",
    ["Combined User"]: "",
    ["Organization Super"]: "",

    ["Email Templates"]: "",
    ["All Dealers"]: "",
    ["Registration Emails"]: "",
    ["New Account"]: "",
    ["Forgot Password"]: "",
    ["Forgot UserName"]: "",
    ["Account Locked"]: "",
    ["Retailer Emails"]: "",
    ["Invite New Retailer"]: "",
    ["Community Emails"]: "",
    ["Retailer Request e-Mail to"]: "",
    ["ACTIVE"]: "",
    ["GF Members"]: "",
    ["Retailer e-Mail to"]: "",
    ["COMMUNITY MANAGED"]: "",
    ["Non GF Members"]: "",
    ["PROFILE ONLY"]: "",
    ["LIMITED ACCOUNT"]: "",
    ["Vendor Decline eMail"]: "",
    ["Vendor Accept eMail"]: "",
    ["Request to Update Product Data"]: "",
    ["Vendor Appointment"]: "",
    ["Invite New Vendor"]: "",
    ["Contact Emails (MasterLink)"]: "",
    ["Customer Registration"]: "",
    ["New Appointments"]: "",
    ["Inquires (Request More Info)"]: "",
    ["Send Mail to Retailer"]: "",
    ["Send Mail to Sender"]: "",
    ["Send Email (Email To Friend)"]: "",
    ["Send Mail to Friend"]: "",
    ["Drop A Hint"]: "",
    ["Send Mail to Recipient"]: "",
    ["New Favorite List"]: "",
    ["New Favorite Items Added"]: "",
    ["Customer Reset Password"]: "",
    ["Customer Reset Password (New)"]: "",
    ["Diamond Search Emails"]: "",
    ["Request More Info"]: "",
    ["Email To Friend"]: "",
    ["Schedule View"]: "",
    ["Diamond Detail"]: "",
    ["Diamond Compare"]: "",
    ["Diamond Customer Compare"]: "",
    ["Fancy Color Diamond Search Emails"]: "",
    ["Color Diamond Detail"]: "",
    ["Ring Builder Emails"]: "",
    ["Drop A Hint (Infinity)"]: "",
    ["Buy Now (Infinity)"]: "",
    ["Setting Detail"]: "",
    ["Complete Ring Specifications"]: "",
    ["Pendant Builder Emails"]: "",
    ["Diamond Request More Info"]: "",
    ["Complate Pendant Setting"]: "",
    ["Stud Builder Emails"]: "",
    ["Request More Retailer"]: "",
    ["SB To ScheduleView"]: "",
    ["SB Complete Earring To ScheduleView"]: "",
    ["Diamond Email To Friend"]: "",
    ["Store"]: "",
    ["Big Emails"]: "",
    ["Email To Customer"]: "",
    ["Send Mail to Customer"]: "",
    ["Send Email"]: "",

    ["Default Data Map"]: "",
    ["Product ID & Description"]: "",
    ["Gemfind Column"]: "",
    ["Active Status"]: "",
    ["Category"]: "",
    ["Categories"]: "",
    ["Attributes"]: "",
    ["Gemstones"]: "",
    ["Watches"]: "",
    ["Save Details"]: "",
    ["Create"]: "",

    ["Manage Current Metal Market"]: "",
    ["Current Metal Price"]: "",
    ["Silver"]: "",
    ["Gold"]: "",
    ["Platinum"]: "",
    ["Palladium"]: "",
    ["Enter New Metal Type"]: "",

    ["Trade Show"]: "",
    ["Show"]: "",
    ["Dates"]: "",
    ["Add Trade Show"]: "",
    ["Edit Trade Show"]: "",
    ["JPG Format"]: "",
    ["Show Name"]: "",
    ["Website"]: "",
    ["Show Start Time"]: "",
    ["Show End Time"]: "",
    ["AM"]: "",
    ["Closed"]: "",
    ["PM"]: "",
    ["Show Start Dates"]: "",
    ["Show End Dates"]: "",
    ["Delete Trade Show"]: "",
    ["Are You Sure Want to delete"]: "",

    ["Sr. No."]: "",
    ["Color Name"]: "",
    ["Edit Color"]: "",

    ["API Type"]: "",
    ["Update"]: "",

    ["Mailing Lists"]: "",
    ["Alias Address"]: "",
    ["Number Of Recipients"]: "",
    ["Created"]: "",
    ["Recipients"]: "",
    ["Sync"]: "",
    ["Recipient Address"]: "",
    ["Client"]: "",

    ["Select Campaign"]: "",
    ["Campaign Name"]: "",
    ["Submitted"]: "",
    ["Delivered"]: "",
    ["Total Opens"]: "",
    ["Total Clicks"]: "",

    ["Select Compose"]: "",
    ["From Name"]: "",
    ["From Email"]: "",
    ["Type Your Subject"]: "",
    ["Send"]: "",

    ["From Date"]: "",
    ["To Date"]: "",
    ["Type"]: "",
    ["Initiated"]: "",
    ["Complete"]: "",

    ["Sync Type"]: "",
    ["Show Full Syncs"]: "",
    ["Show Update Syncs"]: "",
    ["Show Delete Syncs"]: "",
    ["Show Price Syncs"]: "",
    ["Sync Status"]: "",
    ["Show Success Syncs"]: "",
    ["Show Failed Syncs"]: "",
    ["Show Pending Syncs"]: "",

    ["Mastelink"]: "",
    ["Watch Builder"]: "",
    ["Version"]: "",

    ["Diamonds"]: "",
    ["Open Requests"]: "",
    ["Last Updated"]: "",
    ["Days Old"]: "",
    ["Details"]: "",

    ["Metal Types"]: "",
    ["Metal Colors"]: "",
    ["Diamond Pairs"]: "",
    ["Default Mapping Values"]: "",
    ["Heirachy"]: "",

    ["Count"]: "",
    ["Last Upload Date"]: "",

    ["Select Diamond"]: "",
    ["Top Diamond Attribute Values"]: "",
    ["Recent Diamond Attribute Searches"]: "",
    ["Depth"]: "",
    ["Table"]: "",
    ["Measurements"]: "",
    ["Date"]: "",
    ["Shape"]: "Forma",
    ["Size"]: "",
    ["Color"]: "",
    ["Cut"]: "",
    ["Clarity"]: "",
    ["Price"]: "",
    ["UsersIPAddress"]: "",
    ["Day & Time"]: "",
    ["Certificates"]: "",
    ["Retail Price"]: "",
    ["Cost"]: "",

    ["Extended Diamond Chart Reports"]: "",
    ["Vendor(s)"]: "",
    ["Retailer(s)"]: "",
    ["Diamond Cut Searches"]: "",
    ["Diamond Shape Searches"]: "",
    ["Diamond Clarity Searches"]: "",
    ["Diamond Certificate Searches"]: "",
    ["Displays the total number of jewelry clicks through the currently specified date range above"]:
      "",

    ["Download CSV"]: "",
    ["Total Jewelry Clicks"]: "",
    ["Jewelry Clicks Based on Category"]: "",
    ["Jewelry Clicks Based on Price"]: "",
    ["Jewelry Clicks Based on Vendor"]: "",

    ["Product Image"]: "",
    ["User IP Address"]: "",
    ["Collection"]: "",
    ["Style Number"]: "",
    ["Metal Type"]: "",
    ["Metal Color"]: "",

    ["Show Filter"]: "",
    ["Hide Filter"]: "",
    ["Shapes"]: "",
    ["Round"]: "",
    ["Oval"]: "",
    ["Circel"]: "",
    ["Heart"]: "",
    ["Emarald"]: "",
    ["Radiant"]: "",
    ["Caret"]: "",
    ["Carats"]: "",
    ["Polish"]: "",
    ["Length(mm)"]: "",
    ["Width(mm)"]: "",
    ["height(mm)"]: "",
    ["Symmetry"]: "",
    ["Girdle"]: "",
    ["Crown Angle"]: "",
    ["Pavilion Angle"]: "",
    ["Culet"]: "",
    ["Culet Condition"]: "",
    ["Fluorescence"]: "",
    ["Fancy Color"]: "",
    ["Intensity"]: "",
    ["Overtone"]: "",
    ["Matched Pairs"]: "",
    ["Has Video"]: "",
    ["Reset Filters"]: "",
    ["Apply Filters"]: "",
    ["You Have"]: "",
    ["View"]: "",
    ["Print"]: "",
    ["View Details"]: "",
    ["Contact Dealer"]: "",
    ["Message to B&H Diamonds"]: "",
    ["Contact the dealer regarding"]: "",
    ["GemFind"]: "",
    ["Carat Weight"]: "",
    ["Treatment"]: "",
    ["Crown"]: "",
    ["Lab"]: "",
    ["Matched Pair Stock"]: "",
    ["Seller"]: "",
    ["Stock"]: "",
    ["Stock #"]: "",
    ["Off %"]: "",
    ["Flour"]: "",
    ["Dep."]: "",
    ["Measure"]: "",
    ["Cert."]: "",
    ["Sym"]: "",
    ["Image/Video"]: "",
    ["Video"]: "",
    ["Close"]: "",

    ["Colors"]: "",
    ["Blue"]: "",
    ["Orange"]: "",
    ["Green"]: "",
    ["Yellow"]: "",
    ["Red"]: "",
    ["Maroon"]: "",
    ["Permission"]: "",
    ["Fancy Intensity"]: "",
    ["Overtone 1"]: "",
    ["Overtone 2"]: "",
    ["Overall Price"]: "",
    ["Price per Caret Range"]: "",
    ["Eye Clean"]: "",
    ["Fluorescence Intensity"]: "",
    ["Fluorescence Color"]: "",
    ["L/W Ratio Measurements"]: "",
    ["LW Ratio"]: "",
    ["Any"]: "",
    ["Square"]: "",
    ["Rectangle"]: "",
    ["Shade"]: "",
    ["Central Inclusion Range"]: "",
    ["Black Inclusion Range"]: "",
    ["Milky Inclusion Range"]: "",
    ["Girdle Range"]: "",
    ["Crown Angle % Range"]: "",
    ["Pavilion Angle % Range"]: "",
    ["Key to Symbols"]: "",
    ["Culet Range"]: "",
    ["Culet Condition Range"]: "",
    ["price(Cost)"]: "",

    ["There are"]: "",
    ["results for your search"]: "",
    ["Material Type"]: "",
    ["Gender"]: "",
    ["Material Color"]: "",
    ["Gemstone"]: "",
    ["Retaile Stock #"]: "",
    ["Retail"]: "",
    ["Gender is not a required field for vendor data, so by leaving this blank, the majority of product results will be considered Ladies."]:
      "",
    ["Please select a vendor before choosing a collection."]: "",
    ["Retail Stock #"]: "",
    ["$ cost"]: "",
    ["$ Retail"]: "",

    ["Store Name"]: "",
    ["Retailer Type"]: "",
    ["Requested Date"]: "",
    ["Request Date"]: "",
    ["My Retailer"]: "",
    ["My Retailers"]: "",
    ["Jewelry API"]: "",
    ["All Retailer"]: "",
    ["All Retailers"]: "",

    ["Profile"]: "",
    ["Conversation"]: "",
    ["Notes"]: "",
    ["Appointments"]: "",
    ["Time"]: "",
    ["Buyer Name"]: "",

    ["Action Request"]: "",

    ["Activity"]: "",
    ["Customer"]: "",

    ["Load More"]: "",

    ["View My Mined Diamond"]: "",
    ["Are you sure to delete this diamond"]: "",

    ["View My Lab Diamond"]: "",

    ["View My Color Diamond"]: "",

    ["Diamond Upload History"]: "",
    ["Data Upload"]: "",
    ["Image Upload"]: "",
    ["File"]: "",
    ["Records"]: "",

    ["Inventory #"]: "",
    ["Matched Pair Stock #"]: "",
    ["Stones (Parcel)"]: "",
    ["Separable"]: "",
    ["Fancy Codes"]: "",
    ["Fancy Names"]: "",
    ["Other"]: "",
    ["Cut Grade"]: "",
    ["Certificate #"]: "",
    ["Pavillion Angle"]: "",
    ["Comments"]: "",
    ["Store Location"]: "",
    ["Employee Name"]: "",
    ["Employee ID"]: "",
    ["Out On Memo"]: "",
    ["Seen by Other Dealers"]: "",
    ["Add Video"]: "",

    ["Upload Your Data File"]: "",
    ["Add Individual"]: "",
    ["Upload Certs"]: "",
    ["Download Data"]: "",
    ["Please keep your inventory current. Your diamond inventory will be removed if not updated at least every 48 hours."]:
      "",
    ["Choose File"]: "",
    ["Xls/Txt/Csv Format"]: "",
    ["Xls/Txt/Csv/Json Format"]: "",
    ["Upload Data"]: "",
    ["Upload Diamond Images & Videos"]: "",
    ["Jpg/Png Format"]: "",
    ["Compress images together & upload in a .zip format."]: "",
    ["Download our image guidelines documentation"]: "",
    ["Import Data From 3rd Party Provider"]: "",
    ["Diamond uploads are limited to 50,000 diamonds to ensure system speed and reliability. Diamond Files with more than 50,000 diamonds will be cut off so please review your diamond vendors to endure you are within the diamond limit"]:
      "",
    ["Add new diamonds in bulk or make mass item updates"]: "",
    ["First time importing?"]: "",
    ["Import Your Content"]: "",
    ["Choose & upload your data or connect to a 3rd party"]: "",
    ["Wait For Confirmation"]: "",
    ["You will receive an e-mail confirmation when your content has been uploaded."]:
      "",
    ["Inventory with Dealer Stock # not included in the upload will be deleted."]:
      "",
    ["Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched."]:
      "",

    ["Select Data file to be"]: "",
    ["Mapped"]: "",
    ["Upload File"]: "",
    ["Reset the data map"]: "",
    ["View / Edit the map"]: "",
    ["Manage FTP settings"]: "",
    ["Generate File"]: "",
    ["GF Column ID"]: "",
    ["Your Column"]: "",

    ["Manage FTP Credential"]: "",
    ["FTP URL"]: "",

    ["Request Access"]: "",
    ["Your Diamonds"]: "",
    ["Connected Diamonds"]: "",
    ["Total Diamonds"]: "",
    ["Lab Diamonds"]: "",
    ["Color Diamond Count"]: "",
    ["Your Products"]: "",
    ["Connected Products"]: "",
    ["Active Products"]: "",
    ["EDGE"]: "",
    ["Import Type"]: "",

    ["Update History"]: "",

    ["Create Product"]: "",
    ["More Actions"]: "",
    ["Total"]: "",
    ["Product"]: "",
    ["Select All"]: "",
    ["Images"]: "",
    ["Style No"]: "",
    ["GFinventory No"]: "",
    ["Product Name"]: "",
    ["Wholesale Price"]: "",
    ["Gemfind#"]: "",
    ["Feature"]: "",
    ["Are you sure you want to delete this Product(s)?"]: "",

    ["Add Image"]: "",
    ["OR"]: "",
    ["Load Image From Server"]: "",
    ["Load Image"]: "",
    ["Retail Description"]: "",
    ["Style #"]: "",
    ["This must be a unique item number identifying the particular item or the"]:
      "",
    ["parent"]: "",
    ["item which has many variations - if this is a style root then you will want to set this iem as a"]:
      "",
    ["configurable product"]: "",
    ["to configure the variables such as carat weight, metal type, diamond quality, length etc."]:
      "",
    ["Dealer Stock #"]: "",
    ["This is a unique identification number of this particular item."]: "",
    ["Period"]: "",
    ["Visibility"]: "",

    ["Additional"]: "",
    ["Qty On Hand"]: "",
    ["Ask us about how we can set an automated connection to your inventory system and dynamically track the available of your virtual inventory."]:
      "",
    ["Display Order"]: "",
    ["This refers to the order in which the item will appear within its category - lowest number first. Items with the same number are sorted based on least expensive to most expensive."]:
      "",
    ["Setting"]: "",
    ["Finishing Technique"]: "",
    ["Clasp Type"]: "",
    ["Chain Type"]: "",
    ["Back Finding"]: "",
    ["Additional Information"]: "",
    ["Additional Information 2"]: "",

    ["Sizing"]: "",
    ["General"]: "",
    ["Weight"]: "",
    ["grams"]: "",
    ["dwt"]: "",
    ["Thickness"]: "",
    ["mm"]: "",
    ["inches"]: "",
    ["Width"]: "",
    ["Dimensions"]: "",
    ["Unit Of Measure"]: "",
    ["Finger Size"]: "",
    ["Stock Finger Size"]: "",
    ["Min Finger Size"]: "",
    ["Max Finger Size"]: "",
    ["Finger Size Increment"]: "",
    ["Price Type"]: "",
    ["Additional Price"]: "",

    ["Pricing"]: "",
    ["Wholesale Price Method"]: "",
    ["'Manual' means that you must set the price manually. 'Relational' means that the GF system uses a 'Relational Price Factor' to adjust the price based on current metal market pricing. 'Cost Basis' means that you supply the component breakdown of the item and adjust global price factors to recalculate price each day based on current metal market conditions.If the wholesale price field OR the retail price fields are blank, then the system will display 'Call For Price'"]:
      "",
    ["Wholesale Base Price"]: "",
    ["Base Metal Market"]: "",
    ["Price Factor"]: "",
    ["Calculate"]: "",
    ["Relational Price"]: "",
    ["Relational Metal Market"]: "",
    ["Metal Labor Code"]: "",
    ["Other Labor Code"]: "",
    ["Metal Factor Code"]: "",
    ["GPM Code"]: "",
    ["Retail Price Method"]: "",
    ["MSRP"]: "",
    ["To improve data quality, please let us know how you want the pricing of this product managed. 1 - Enforce MSRP. 2 - Provide a 'Suggested Retail Price' & Allow Retail Markups. 3 - Allow Retail Markups. 4 - Call For Price. 5 - Pricing Starting At."]:
      "",

    ["Related"]: "",
    ["Matching Styles"]: "",
    ["Matching Sku's are items from the same ensamble such as a matching ring earrings and pendant. Enter Dealer ID or Style #' separated with commas."]:
      "",
    ["Up Sell Styles"]: "",
    ["Up sell Sku's are items that are related by at slightly higher price points."]:
      "",
    ["Grouped Product Styles"]: "",
    ["There is no content."]: "",

    ["Media"]: "",
    ["Choose Video"]: "",
    ["Load Video From URL"]: "",
    ["Load Video"]: "",
    ["Add Video Type"]: "",
    ["Label"]: "",

    ["Bullet Point 1"]: "",
    ["Bullet Point 2"]: "",
    ["Bullet Point 3"]: "",
    ["Bullet Point 4"]: "",
    ["Bullet Point 5"]: "",
    ["Discount A"]: "",
    ["Qty 1"]: "",
    ["Qty 2"]: "",
    ["Qty 3"]: "",
    ["Qty 4"]: "",
    ["Qty 5"]: "",
    ["Retailer Brand Name"]: "",
    ["Secondary Metal Type"]: "",
    ["Amazon Product"]: "",

    ["Total Diamond Weight"]: "",
    ["Total Gemstone Weight"]: "",
    ["Gemstone Type"]: "",
    ["Dimension Unit Of Measure"]: "",
    ["Number Of Gemstones"]: "",
    ["Gemstone Shape"]: "",
    ["Gemstone Origin"]: "",
    ["Gemstone Carat Weight"]: "",
    ["Gemstone Dimensions"]: "",
    ["Gemstone Quality"]: "",

    ["Watch"]: "",
    ["Band Material"]: "",
    ["Number Type"]: "",
    ["Band Type"]: "",
    ["Case Material"]: "",
    ["Case Shape"]: "",
    ["Comes Packaged In"]: "",
    ["Crystal Type"]: "",
    ["Dial Color"]: "",
    ["Manufacture Date"]: "",
    ["Display Type"]: "",
    ["Certification"]: "",
    ["Movement"]: "",
    ["Energy"]: "",

    ["Save Product"]: "",
    ["Cancel Product"]: "",

    ["Upload Your Images"]: "",
    ["Please wait while file is Uploading.."]: "",
    ["Backgrounds should be white or transparent."]: "",
    ["Images should be larger than 500 x 500 pixels."]: "",
    ["Products should occupy more than 80% of canvas."]: "",
    ["Questions? View the"]: "",
    ["Image Guidelines"]: "",
    ["Help Doc"]: "",
    ["Your File is InProgress"]: "",
    ["Please click here to see history"]: "",
    ["Load Images From My Server"]: "",
    ["If you want to add jewelry images direct from server like: https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as checked. Also verify that you have also set column IsImageFromUrl in your file. If you have set 1 to this column then image will load direct from server and if you have set 0 then imagepath will be treated as simple"]:
      "",
    ["Jewelry Import"]: "",
    ["Add new jewelry items in bulk or make mass item updates."]: "",
    ["Map Your Content"]: "",
    ["Match your categories with the JewelCloud industry standard making your content compatible with our network and applications."]:
      "",
    ["Required if your data file is not in our format."]: "",
    ["Download Templates"]: "",
    ["Required Fields Form"]: "",
    ["Simple Product Forms"]: "",
    ["Ring Builder Data Form"]: "",
    ["Configurable Product Form"]: "",
    ["Help Docs"]: "",
    ["Image Guideline"]: "",
    ["Progress"]: "",
    ["Ok"]: "",
    ["Uploaded"]: "",
    ["Uploading file"]: "",

    ["Create Collection"]: "",
    ["All Collections"]: "",
    ["House Brand Collections"]: "",
    ["Vendor Collections"]: "",
    ["Vendor Extensions"]: "",
    ["Collection Name"]: "",
    ["Brand Name"]: "",
    ["Override"]: "",
    ["Enable"]: "",
    ["Markup Override"]: "",
    ["Disable"]: "",
    ["Percentage"]: "",
    ["Delete Collection"]: "",
    ["Are you sure , you want to delete this Collection."]: "",

    ["content not provided."]: "",
    ["General Settings"]: "",
    ["Currency"]: "",
    ["Rounding"]: "",
    ["Default Markup"]: "",
    ["Markup"]: "",
    ["Mrk"]: "",
    ["Markup By Price Range"]: "",
    ["Markup By Size Range"]: "",
    ["Min Price"]: "",
    ["Max Price"]: "",
    ["Mark Up"]: "",
    ["Min Size"]: "",
    ["Max Size"]: "",

    ["Overrides"]: "",
    ["Save Pricing"]: "",

    ["Edit Collection"]: "",
    ["Create A Collection"]: "",
    ["Items you add for your store's personal use. Collections can be added to a JewelCloud Vendor as an Extension of their official profile or your own House Brand"]:
      "",
    ["View The Training Video"]: "",
    ["Collection Description"]: "",
    ["Collection Display Order"]: "",
    ["Navigation Category"]: "",
    ["Bridal"]: "",
    ["Fashion"]: "",
    ["Giftware"]: "",
    ["Save Collection"]: "",

    ["Import Mapping"]: "",
    ["Export Mapping"]: "",
    ["Add New Column"]: "",
    ["Attribute"]: "",
    ["JewelCloud defined column name."]: "",
    ["This defines what type of data is allowed. Fixed lables means you must use an approved JewelCloud value. Numeric can only contain numbers and decimal."]:
      "",
    ["Requirement"]: "",
    ["Data uploads will fail if these columns do not contain a value."]: "",
    ["Your Attribute"]: "",
    ["Non Mapped"]: "",
    ["Accepted Values"]: "",
    ["Required"]: "",
    ["Example"]: "",
    ["Feild Type"]: "",
    ["Configurable"]: "",
    ["Definition"]: "",
    ["Remaining"]: "",
    ["Drag & Drop Your Attributes"]: "",
    ["Match Your attributes with the jewel cloud industry standard"]: "",
    ["Attribute Value Map"]: "",
    ["Match Your Variables to the jewelCloud Industry Standard"]: "",
    ["Your sheet just needs your column headers."]: "",
    [".xls | .txt | .csv"]: "",
    ["Choose Format"]: "",
    ["No content"]: "",
    ["Comma"]: "",
    ["Tab"]: "",
    ["Upload Sheet"]: "",
    ["Import Mapping Template"]: "",
    ["Edge TPW"]: "",
    ["Arms"]: "",
    ["Edge EDT"]: "",
    ["Load File"]: "",
    ["Attribute A New Mappable Column"]: "",
    ["Add A Single New Column Header."]: "",
    ["Column ID"]: "",
    ["Column Name"]: "",
    ["Image Upload Settings"]: "",
    ["Import Multiple"]: "",
    ["True"]: "",
    ["False"]: "",
    ["Select Delimiter"]: "",
    ["( |(pipe sign) ,(comma) ) Use this single Delimiter to separate Images."]:
      "",
    ["Add Price Type Override"]: "",
    ["Enforce MSRP"]: "",
    ["SuggestedRetail Price & Allow Retail Markups"]: "",
    ["Allow Retail Markups"]: "",
    ["Call For Price"]: "",
    ["Prices Starting at"]: "",
    ["Allow price type override"]: "",
    ["Reset The Map"]: "",
    ["Yikes...Go Back"]: "",
    ["Are your sure you want to reset the Data Map?"]: "",
    ["THERE IS NO UNDO"]: "",

    ["Diamond Chart Reports"]: "",
    ["Export to PDF"]: "",
    ["Diamond Clicks for GemFind (720)"]: "",
    ["Events"]: "",
    ["Download PDF"]: "",
    ["IPAddress"]: "",
    ["Lab Grow Diamond"]: "",
    ["Round to the nearest 1"]: "",

    ["Diamond Cut Grade Report"]: "",

    ["Location Activity"]: "",
    ["Types"]: "",

    ["Accept New Collection"]: "",
    ["If selected you will receive new collections from this vendor, If not selected, you will only recieve updates to existing collections"]:
      "",

    ["Retailer Affirmation"]: "",
    ["Current Authorized Reseller"]: "",
    ["By checking this box and clicking the submit button below I hereby affirm that GemFind has an open and active account with A & D Gem Corp, that I am an authorized reseller of A & D Gem Corp, and that I am permitted to have their virtual inventory on my website."]:
      "",
    ["New Account Request"]: "",
    ["I am interested in opening a new account with A & D Gem Corp and becoming an authorized reseller. By checking this box and clicking the submit button below, your name and store information will be sent to A & D Gem Corp."]:
      "",

    ["Invite Your Vendors"]: "",
    ["E-Mail Address"]: "",
    ["Send Invitation"]: "",
    ["Last Requested"]: "",

    ["SKU"]: "",
    ["InHouse"]: "",
    ["Measurement"]: "",
    ["Cert"]: "",
    ["Mined"]: "",
    ["Princess"]: "",
    ["Pear"]: "",
    ["Marquise"]: "",
    ["Ideal"]: "",
    ["Excellent"]: "",
    ["Very Good"]: "",
    ["Good"]: "",
    ["Fair"]: "",
    ["Diamond Preview"]: "",
    ["Sample Image"]: "",
    ["Stock Number"]: "",
    ["Add To Compare"]: "",
    ["SIMILAR DIAMONDS"]: "",
    ["Compare"]: "",
    ["Fancy Colored"]: "",
    ["D"]: "",
    ["E"]: "",
    ["F"]: "",
    ["G"]: "",
    ["I"]: "",
    ["J"]: "",
    ["K"]: "",
    ["L"]: "",
    ["M"]: "",
    ["N"]: "",
    ["O"]: "",
    ["P"]: "",
    ["Lab Growm"]: "",
    ["No Diamonds Listed In That Range"]: "",
    ["Please enter your contact information. One of our diamond specialists will contact you and assist you with your needs."]:
      "",
    ["First Name"]: "",
    ["Last Name"]: "",
    ["Add Comments Here"]: "",
    ["Request"]: "",
    ["Please enter the details of the type of diamond you are looking for and your contact information."]:
      "",
    ["Your information will be submitted to one of our diamond specialists that will contact you."]:
      "",
    ["Advance"]: "",
    ["Use your diamond comparison to save all the details of the diamonds you are considering and view them all together"]:
      "",
    ["This makes it easy to choose the right diamond."]: "",

    ["Mined Diamonds"]: "",
    ["Lab Grown"]: "",
    ["Color Diamonds"]: "",

    ["ID"]: "",
    ["Certs"]: "",
    ["Method"]: "",
    ["Custom Method"]: "",
    ["In order to protect the integrity of the diamond and the jewelry industry, the minimum mark up on the Diamond link should be no less than 7%"]:
      "",
    ["Markup By Vendor"]: "",
    ["Markup By PriceRange With Certs"]: "",
    ["Markup By SizeRange With Certs"]: "",
    ["CL"]: "",
    ["Update Markup"]: "",
    ["GIA"]: "",
    ["AGS"]: "",
    ["EGL"]: "",
    ["IGI"]: "",

    ["Enable Diamonds with"]: "",
    ["Video Only"]: "",
    ["Enable Dynamic Range"]: "",
    ["EGL Belgium"]: "",
    ["EGL International"]: "",
    ["EGL Israel"]: "",
    ["EGL South Africa"]: "",
    ["EGL USA"]: "",
    ["HRD"]: "",
    ["GCAL"]: "",
    ["None"]: "",

    ["FNT"]: "",
    ["MED"]: "",
    ["ST"]: "",
    ["ALL"]: "",

    ["Configurable Attribute"]: "",
    ["Shipping Charge"]: "",
    ["Update Settings"]: "",

    ["Standard Search"]: "",
    ["Advanced Search"]: "",
    ["Fancy Colored Search"]: "",
    ["Lab Grown Search"]: "",
    ["Request a Diamond"]: "",
    ["Compare Diamonds"]: "",
    ["Left Navigation for MasterLink"]: "",
    ["Loose Diamonds"]: "",
    ["Same Window"]: "",
    ["New Window"]: "",
    ["Inframe"]: "",
    ["Our Signature Diamonds"]: "",
    ["Best Value Diamonds"]: "",
    ["Private Reserve Diamonds"]: "",
    ["HeartStar Diamonds"]: "",

    ["Change this value to set the TITLE at the top of the search results page. Leave blank for default value."]:
      "",
    ["Change this value to set the TITLE at the top of the diamond details page. Leave blank for default value."]:
      "",
    ["Change this value to set the TEXT of the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "",
    ["Change this value to set the URL for the #1 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "",
    ["Change this value to set the TEXT of the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "",
    ["Change this value to set the URL for the #2 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "",
    ["Change this value to set the TEXT of the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "",
    ["Change this value to set the URL for the #3 link at the bottom of the search restuls page. Leave blank for default value (none)."]:
      "",
    ["Change this value to allow your GFLink users to search for matched pairs of stones in addition to single stones (also ALL stones). Leave blank for default value (single stones only)."]:
      "",
    ["Use this option to Enable (Yes) or Disable (No) display of Logo (i.e. RESULTS PAGE IMAGE URL) on the top of Search Result Page."]:
      "",
    ["Change this value to alter the URL associated with the image displayed at the top of the search results page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "",
    ["Change this value to alter the actual hyperlink associated with the image displayed at the top of the search results page. Leave blank for default value (none)."]:
      "",
    ["Change this value to alter the URL associated with the image displayed in the middle of the default diamond details page. Leave blank for default value (none). If you send your image to GemFind to place on a GemFind server, you can put in the filename only."]:
      "",
    ["Change this value to alter the visible text of the actual hyperlink to the diamond details. Leave blank for default value."]:
      "",
    ["By default, your search will display basic diamond details (Cut, Color, Clarity, Size, Price & Certificate) on the search results page. You may choose to display more detailed information (Table, Depth, Measurements) as well as the basic information on the search results page. No matter which method you select, each diamond's complete details are always available by clicking 'More Info' on the search results page."]:
      "",
    ["You may choose to search by Cut Grade if desired. Table & Depth searching is enabled as well as they are directly related to Cut Grade."]:
      "",
    ["Choose the number of diamonds displayed on each page of the search results. For values other than shown, you must contact GemFind directly."]:
      "",
    ["Choose whether to show more obscure diamond cuts (asscher, old miner) on the search form."]:
      "",
    ["Choose whether to use the interactive Macromedia Flash search form instead of the regular search form."]:
      "",
    ["Choose whether to use show popup of diamond details on the diamond inveotry page."]:
      "",
    ["Choose whether to display share button (Face Book, Twitter, Digg etc.) on diamond details page."]:
      "",
    ["Choose whether to link diamond search with new ring builder."]: "",
    ["Choose whether to display Ring Builder image at the right column of new diamond link."]:
      "",
    ["Search Page Title"]: "",
    ["Diamond Detail page Title"]: "",
    ["Hyperlink #1 TEXT at bottom of search results page"]: "",
    ["Hyperlink #1 URL at bottom"]: "",
    ["Hyperlink #2 TEXT at bottom"]: "",
    ["Hyperlink #2 URL at bottomof search results page"]: "",
    ["Hyperlink #3 TEXT at bottom of search results page"]: "",
    ["Hyperlink #3 URL at bottom of search results page"]: "",
    ["Let users search for MATCHED PAIRS"]: "",
    ["Show Logo on Results Page"]: "",
    ["Show Diamond Education on Results Page"]: "",
    ["Results page image URL"]: "",
    ["Results page image hyperlink URL"]: "",
    ["Diamond details page image URL"]: "",
    ["Alternate diamond details link text"]: "",
    ["Enhanced details in search results"]: "",
    ["Search By Cut Grade"]: "",
    ["Number of diamonds displayed per page"]: "",
    ["Show odd diamond cuts on search form"]: "",
    ["Dynamic Flash search form"]: "",
    ["Show popup on Diamond inventory display page"]: "",
    ["Show Share Button on Details Page (for New GF Link only)"]: "",
    ["Use New Ringbuilder"]: "",
    ["Show Ring Builder Image (for New GF Link only)"]: "",

    ["Generate API Key to get data"]: "",
    ["Generate"]: "",
    ["API Documentation"]: "",

    ["Markup="]: "",
    ["Markups"]: "",

    ["Setting #"]: "",
    ["Vendor #"]: "",
    ["Metal"]: "",
    ["$ Price"]: "",
    ["$ Markup"]: "",

    ["Setup"]: "",
    ["Ring Builder URL"]: "",
    ["Enter the URL of this tool on your site to enable social sharing and dynamic sizing."]:
      "",
    ["Internal Use Link"]: "",
    ["Choose Weather to display the “For Internal Use Only” link at the bottom of the Setting Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed setting."]:
      "",
    ["Display"]: "",
    ["RingSize Type"]: "",
    ["Choose which Ring Size format you would like to display. (American - 1, 1.5, 2, etc. or British - A, A.5, B, B.5 etc.)."]:
      "",
    ["American"]: "",
    ["British"]: "",
    ["Roman"]: "",
    ["Europe"]: "",
    ["Diamond Initially Sort Result On"]: "",
    ["Select a diamond attribute that you would like to initially sort the diamonds by."]:
      "",
    ["Ascending"]: "",
    ["Descending"]: "",
    ["Default Metal Type on Setting page"]: "",
    ["14K White Gold"]: "",
    ["14K Yellow Gold"]: "",
    ["Show Advanced Search Options By Default"]: "",
    ["Choose Whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "",
    ["Rings - Show Schedule A Viewing"]: "",
    ["Choose Whether to display the link which allows the customer the ability to schedule an appointment."]:
      "",
    ["Show Addresses"]: "",
    ["Choose Whether to display your company address and contact information within the RingBuilder."]:
      "",
    ["Show Center Stone Size"]: "",
    ["Warning: Disabling this option will remove the Carat Range restriction for Ring Settings, and may cause an increase in cost prices from what is stated for the selected Ring Setting. For more information follow up with Overnight Mountings."]:
      "",
    ["Show Price"]: "",
    ["Choose Whether to show prices for ring settings or not. If you choose NO, then it will instead display “Call for Price”."]:
      "",
    ["Apply GST Tax"]: "",
    ["Toggle the ability to apply international tax."]: "",
    ["GST Tax Value"]: "",
    ["Set the value when applying the GST Tax option."]: "",
    ["Social"]: "",
    ["Show Pinterest Share"]: "",
    ["Choose whether to display the Pinterest “Pin it” button or not."]: "",
    ["Show Twitter Share"]: "",
    ["Choose whether to display the Twitter “Tweet” button or not."]: "",
    ["Show Facebook Share"]: "",
    ["Choose whether to display the Facebook “Share” button or not."]: "",
    ["Show Facebook Like"]: "",
    ["Choose whether to display the Facebook “Like” button or not."]: "",
    ["Show Google Plus"]: "",
    ["Choose whether to display the Google “G+1” button or not."]: "",
    ["E-Commerce"]: "",
    ["Show Add To Cart Button"]: "",
    ["Alternate Shopping Cart"]: "",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond and setting detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "",
    ["Show Buy Setting Only"]: "",
    ["Choose Whether to display the “Buy Setting Only” button or not. This allows the customer to use the RingBuilder to only purchase the ring setting as opposed to the completed ring with diamond."]:
      "",
    ["Show Buy Diamond Only"]: "",
    ["Choose Whether to display the “Buy Diamond Only” button or not. This allows the customer to use the RingBuilder to only purchase the diamond as opposed to the completed ring."]:
      "",
    ["View Cart"]: "",
    ["Button"]: "",
    ["Choose Whether to display the PayPal shopping cart's view button or Not"]:
      "",

    ["No content."]: "",
    ["Page Alignment"]: "",
    ["Center"]: "",
    ["Left"]: "",
    ["Right"]: "",
    ["Show Social Sharing Buttons"]: "",
    ["Show View In Store"]: "",
    ["Show Pop Up On Diamond Search"]: "",
    ["Diamond Search Result Page Size"]: "",
    ["Rings - Search Result Page Size"]: "",
    ["Rings - Initially Sort Result On"]: "",
    ["Setting Number"]: "",

    ["Send Email To Friend"]: "",
    ["Send Email To Retailer"]: "",
    ["Send Email To Sender"]: "",
    ["Request A Diamond Email"]: "",
    ["Request A Diamond"]: "",
    ["Send Mail to Request A Diamond"]: "",

    ["Wishlist"]: "",
    ["Bridal Registry"]: "",
    ["Shopping Cart"]: "",
    ["Show Store Hours"]: "",
    ["Website URL"]: "",
    ["Google Analytic ID"]: "",
    ["Show Only MSRP Prices"]: "",
    ["Masterlink Theme"]: "",
    ["Show View"]: "",
    ["New MasterLink Use In Iframe?"]: "",
    ["Login URL"]: "",
    ["Show WholeSale Price Internal Use Link?"]: "",
    ["Show Address/Phone Details?"]: "",
    ["Diamond Search / Ringbuilder load from Masterlink?"]: "",
    ["Diamond Image URL"]: "",
    ["Vendor Details open or closed by default in new masterlink"]: "",
    ["Show Additional PopUp in new masterlink"]: "",
    ["Shopping Cart page message"]: "",
    ["Hearts On Fire Link"]: "",
    ["Show Setting Name at Setting Grid"]: "",
    ["Show Ring Builder with One Step"]: "",
    ["Show Default Ring Size"]: "",
    ["Shop Diamonds"]: "",
    ["Shop Bridal & Engagement"]: "",
    ["Shop Designers"]: "",
    ["Shop Jewelry"]: "",
    ["Shop Watches"]: "",

    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Diamond Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond."]:
      "",
    ["Sticky Header Height"]: "",
    ["Enter a Sticky Header Height."]: "",
    ["Platinum Education"]: "",
    ["Provides a link to copy for platinum education in Ring Builder."]: "",
    ["Ring Size Guide URL"]: "",
    ["Provides a link to copy for ring sizing guides provided by GemFind."]: "",
    ["Show Breadcrumbs"]: "",
    ["Shows navigation path a customer took to get to page they're on."]: "",
    ["Create Profile"]: "",
    ["text"]: "",
    ["Provide alternate text for user registration Button."]: "",
    ["Sort Products by Price Initially By"]: "",
    ["Allow Customers to Sort Product By Price."]: "",
    ["Price: Low - High"]: "",
    ["Price: High - Low"]: "",
    ["Collection Filtering"]: "",
    ["When navigating Designer Landing pages - show collections instead of categories."]:
      "",
    ["Schedule Viewing"]: "",
    ["Allow Customers to schedule a meeting with you."]: "",
    ["Designer Tile Layout"]: "",
    ["Show Back to Top Button."]: "",
    ["Enabled Back To Top"]: "",
    ["Allow address to show on detailpage"]: "",
    ["Text"]: "",
    ["(When prices have been turned off, by default, it will display “Call for Price”. This option allows you to replace “Call for Price” with alternate text (Ex. “Call for Availability)."]:
      "",
    ["Starting At Price"]: "",
    ["Provide prefix text (it will include price) when a product uses Price Type 5 (Products with “Starting At Price” PriceType (5) cannot be added to the shopping cart)."]:
      "",
    ["instead of the Price"]: "",
    ["Hides the retail price for products using Price Type 1,2,or 3 ."]: "",
    ["Facebook App ID"]: "",
    ["Connect MasterLink to your Facebook page, using your Facebook App ID."]:
      "",
    ["Facebook Like Page URL"]: "",
    ["Allow Like access to your facebook account by providing the URL."]: "",
    ["Allow Customers to post your products to their Pinterest boards."]: "",
    ["Allow Customers to share your products to their Twitter account."]: "",
    ["Allow Customers to share your products to their Facebook page."]: "",
    ["Allow Customers to like your products directly from your website."]: "",
    ["SEO"]: "",
    ["Masterlink Default Title"]: "",
    ["Enter a title for search engine optimization."]: "",
    ["Masterlink Meta Keywords"]: "",
    ["Enter a Keywords for search engine optimization."]: "",
    ["Masterlink Meta Description"]: "",
    ["Enter a description for search engine optimization."]: "",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page."]:
      "",
    ["Enable the View Cart button for customers to review their products selections."]:
      "",

    ["New Favourite List"]: "",
    ["New Favourite Items Added"]: "",

    ["Create Your Own Diamond Studs in 3 easy steps with Stud Builder"]: "",
    ["Select Your Diamonds"]: "",
    ["Choose Your Mounting"]: "",
    ["Complete Your Earrings"]: "",
    ["Standard"]: "",
    ["Add To Stud"]: "",
    ["Advanced"]: "",

    ["Diamonds Markup"]: "",

    ["Diamond Drop A Hint"]: "",
    ["Stud Builder URL"]: "",
    ["Internal Use Link?"]: "",
    ["Choose whether to display the “For Internal Use Only” link at the bottom of the Item Details page, which will allow you to look up the vendor’s stock # and contact info for the viewed diamond pair."]:
      "",
    ["Only Diamond Search"]: "",
    ["Choose whether to ONLY display the Diamond Search portion of Stud Builder which pairs together diamonds of similar attributes."]:
      "",
    ["Show Dealer SKU In Diamond Search"]: "",
    ["Choose whether to display the Dealer/Vendor Stock # instead of the default unique GemFind SKU# (Ex. SKU# 108545370)."]:
      "",
    ["Choose whether to display the link which allows the customer the ability to schedule an appointment."]:
      "",
    ["Allow address to show on detailpage."]: "",
    ["Choose whether to display “Call for Price” instead of the retail price or not."]:
      "",
    ["Shopping Cart Url"]: "",
    ["Change this value to alter the actual hyperlink to the shopping cart. This could be changed to link to your own shopping cart page. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "",
    ["Choose whether to display the “Add to Cart” button or not."]: "",
    ["Choose whether to display the PayPal Shopping Cart's 'View Cart' Button or not."]:
      "",

    ["Generate Sync"]: "",
    ["Generate Full Sync"]: "",
    ["File Name"]: "",
    ["Folder"]: "",

    ["Enter FTP Detail for SYNC File Below"]: "",
    ["User Id"]: "",
    ["Is SSL?"]: "",

    ["Configure & Customize Your"]: "",
    ["Pre-Owned Rolex Watch"]: "",
    ["E-Mail A Friend"]: "",
    ["Print Details"]: "",
    ["Have a question regarding this item? Our specialist are available to assist you. | Call us:"]:
      "",
    ["Items Details"]: "",
    ["STEP 1 | Size & Bracelet Type"]: "",
    ["Choose Watch Type"]: "",
    ["Men's"]: "",
    ["Ladies"]: "",
    ["Mid Size"]: "",
    ["Choose Bracelet Style"]: "",
    ["Jubilee"]: "",
    ["Oyster"]: "",
    ["President"]: "",
    ["Choose Bracelet Metal"]: "",
    ["Stainless Steel"]: "",
    ["18K Y/SS"]: "",
    ["18K Yellow"]: "",
    ["18K White"]: "",
    ["STEP 2 | Bezel Type"]: "",
    ["Choose Bezel Type"]: "",
    ["Choose Bezel Metal"]: "",
    ["Choose Bezel Gemstone Type"]: "",
    ["Choose Bezel Gemstone Shape"]: "",
    ["Choose Bezel Diamond Quality"]: "",
    ["STEP 3 | Dial Type"]: "",
    ["Choose Dial Type"]: "",
    ["Marker Type"]: "",
    ["Custom Dial Colors"]: "",
    ["Specifications"]: "",
    ["Size & Bracelet Type (STEP 1)"]: "",
    ["SKU#"]: "",
    ["Watch Type"]: "",
    ["Men"]: "",
    ["Bracelet Style"]: "",
    ["Bracelet Metal"]: "",
    ["Bezel Type (STEP 2)"]: "",
    ["Custom Bezel Type"]: "",
    ["Custom Bezel Metal"]: "",
    ["Custom Bezel Gemstones"]: "",
    ["Custom Bezel Gemstone Shape"]: "",
    ["Custom Bezel Diamond Quality"]: "",
    ["Total Carat Weight"]: "",
    ["Dial Type (STEP 3)"]: "",
    ["Dial Type"]: "",
    ["Custom Dial Color"]: "",
    ["Your Name"]: "",
    ["Your E-Mail Address"]: "",
    ["Your Company Name"]: "",
    ["Your Phone Number"]: "",
    ["Your Store Phone Number"]: "",
    ["Write A Personal Message"]: "",
    ["Hint Recipient's Name"]: "",
    ["Hint Recipient's E-Mail"]: "",
    ["Reason For This Gift"]: "",
    ["Gift Deadline"]: "",
    ["Add A Personal Message Here"]: "",
    ["Friend E-Mail Address"]: "",
    ["Subject"]: "",
    ["Schedule"]: "",
    ["Your Email"]: "",
    ["Appointment Date"]: "",
    ["Add A Message Here"]: "",
    ["Get Directions"]: "",

    ["Watch Customizer Options"]: "",
    ["Watch Customizer URL"]: "",
    ["Update Setting"]: "",

    ["Symetry"]: "",
    ["Choose Your Setting"]: "",
    ["Complete Your Pendant"]: "",
    ["Add To Your Pendant"]: "",

    ["Pendant Builder URL"]: "",
    ["Enter a URL."]: "",
    ["Show Advanced Search As Default"]: "",
    ["Choose whether to display the Advanced optional filtering by default (Polish, Symmetry, Flourescence, Certs… etc."]:
      "",
    ["Choose whether to display the “View Cart” button or not."]: "",

    ["Total items"]: "",
    ["Total Cost"]: "",

    ["Purchase order date"]: "",
    ["Order Confirm Date"]: "",
    ["Sale Per"]: "",

    ["Confirm"]: "",
    ["Quantity"]: "",
    ["Order Date"]: "",

    ["Folder Path"]: "",
    ["Choose File To Upload"]: "",

    ["Filter By Trade Show"]: "",
    ["Trade Show 1"]: "",
    ["Trade Show 2"]: "",
    ["Trade Show 3"]: "",
    ["Buyer"]: "",

    ["Are you sure to update Permission?"]: "",
    ["Report Permission Control"]: "",
    ["Update Date on which"]: "",
    ["Is Created"]: "",
    ["Select Vendor Status"]: "",
    ["Select Status"]: "",
    ["Company name is compulsory"]: "",
    ["Street address is compulsory"]: "",
    ["City is compulsory"]: "",
    ["City is invalid"]: "",
    ["State is compulsory"]: "",
    ["State is invalid"]: "",
    ["Zip code is compulsory"]: "",
    ["Zip code is invalid"]: "",
    ["Kindly select the country"]: "",
    ["Contact name is compulsory"]: "",
    ["Email address is compulsory"]: "",
    ["Email address is invalid"]: "",
    ["Phone number is compulsory"]: "",
    ["Phone number is invalid"]: "",
    ["Username is compulsory"]: "",
    ["Password is compulsory"]: "",
    ["Password should have a spe"]: "",
    ["Kindly select the permission type"]: "",
    ["Kindly select the retailer status"]: "",
    ["Kindly select the vendor status"]: "",
    ["RetailerCore 1"]: "",
    ["RetailerCore 2"]: "",
    ["RetailerCore 3"]: "",
    ["RetailerCore 4"]: "",
    ["RetailerCore 5"]: "",
    ["RetailerCore 6"]: "",
    ["RetailerCore 7"]: "",
    ["RetailerCore 8"]: "",
    ["RetailerCore 9"]: "",
    ["RetailerCore 10"]: "",
    ["RetailerCore 11"]: "",
    ["RetailerCore 12"]: "",
    ["RetailerCore 13"]: "",
    ["Retailer Id is compulsory"]: "",
    ["Retailer Id is Invalid"]: "",
    ["Kindly select the core"]: "",
    ["Limit Type is compulsory"]: "",
    ["Kindly select the retailer"]: "",
    ["Kindly select the Diamond Dealer"]: "",
    ["Kindly select the Vendor"]: "",
    ["No Dealer Selected"]: "",
    ["Please Select Dealer."]: "",
    ["Select Dealer ID"]: "",
    ["Select File Type"]: "",
    ["Kindly select Dealer ID"]: "",
    ["FTP Username is compulsory"]: "",
    ["FTP Password is compulsory"]: "",
    ["Kindly select File Type"]: "",
    ["Path is Compulsory"]: "",
    ["Dealer ID is compulsory"]: "",
    ["Api Url is compulsory"]: "",
    ["Api Key is compulsory"]: "",
    ["Are you sure you want to discard ?"]: "",
    ["Are you sure you want to discard all ?"]: "",
    ["Enter valid DealerID"]: "",
    ["All Designer"]: "",
    ["Please select designer."]: "",
    ["Please select image."]: "",
    ["View Inquiry"]: "",
    ["Contacts"]: "",
    ["Export"]: "",
    ["Jewelry Retailer Click Report"]: "",
    ["Jewelry Vendor Click Report"]: "",
    ["Global Diamond"]: "",
    ["Extended Diamond Chart Report"]: "",
    ["Global Jewelry"]: "",
    ["Jewelry Category Reports"]: "",
    ["Jewelry Metal Type Reports"]: "",
    ["Page number is compulsory"]: "",
    ["First Tier Page Name is compulsory"]: "",
    ["Second Tier Page Name is compulsory"]: "",
    ["Third Tier Page Name is compulsory"]: "",
    [".JPG | .JPEG | .PNG Format"]: "",
    ["Select Start Time"]: "",
    ["Select End Time"]: "",
    ["Please select the image"]: "",
    ["ShowName is compulsory"]: "",
    ["Website is compulsory"]: "",
    ["Kindly select the Show Start Time"]: "",
    ["Kindly select the Show End Time"]: "",
    ["Kindly select the Show Start Date"]: "",
    ["Kindly select the Show End Date"]: "",
    ["Color Name is compulsory"]: "",
    ["Select"]: "",
    ["Please select compose."]: "",
    ["Please enter name."]: "",
    ["Please enter email."]: "",
    ["Email is not valid."]: "",
    ["Please enter subject."]: "",
    ["Please enter summary."]: "",
    ["Option 1"]: "",
    ["Option 2"]: "",
    ["GIA Website"]: "",
    ["Yes"]: "",
    ["Borderline"]: "",
    ["No"]: "",
    ["Very Slight"]: "",
    ["Slight"]: "",
    ["Faint"]: "",
    ["Medium"]: "",
    ["Strong"]: "",
    ["Very Strong"]: "",
    ["No Yellow"]: "",
    ["No Green"]: "",
    ["No Grey"]: "",
    ["No Black"]: "",
    ["No Pink"]: "",
    ["No Blue"]: "",
    ["No Brown"]: "",
    ["Light"]: "",
    ["Heavy"]: "",
    ["Extremely Thin"]: "",
    ["Very Thin"]: "",
    ["Thin"]: "",
    ["Slightly Thin"]: "",
    ["Slightly Thick"]: "",
    ["Thick"]: "",
    ["Very Thick"]: "",
    ["Extremely Thick"]: "",
    ["Extra Thin"]: "",
    ["Bearding"]: "",
    ["Brown Patch of Color"]: "",
    ["Bruise"]: "",
    ["Cavity"]: "",
    ["Chip"]: "",
    ["Cleavage"]: "",
    ["Cloud"]: "",
    ["Crystal"]: "",
    ["Crystal Surface"]: "",
    ["Etch Channel"]: "",
    ["Extra Facet"]: "",
    ["Feather"]: "",
    ["Flux Remnant"]: "",
    ["Indented Natural"]: "",
    ["Internal Graining"]: "",
    ["Internal Inscription"]: "",
    ["Internal Laser Drilling"]: "",
    ["Knot"]: "",
    ["Laser Drill Hole"]: "",
    ["Manufacturing Remnant"]: "",
    ["Minor Details of Polish"]: "",
    ["Natural"]: "",
    ["Needly"]: "",
    ["No Inclusion"]: "",
    ["Pinpoint"]: "",
    ["Reflecting Surface Graining"]: "",
    ["Surface Graining"]: "",
    ["Twinning Wisp"]: "",
    ["Small"]: "",
    ["Very Small"]: "",
    ["Large"]: "",
    ["Very Large"]: "",
    ["Abraded"]: "",
    ["Chipped"]: "",
    ["Pointed"]: "",
    ["Laser Drilled"]: "",
    ["Clarity Enhanced"]: "",
    ["Color Enhanced"]: "",
    ["Irradiated"]: "",
    ["HPHT"]: "",
    ["Search All Stones"]: "",
    ["Search Single Stone"]: "",
    ["Search Match Pairs"]: "",
    ["Emerald"]: "",
    ["SI1"]: "",
    ["VG"]: "",
    ["B&H Diamonds"]: "",
    ["Chicago, IL"]: "",
    ["Category 1"]: "",
    ["Category 2"]: "",
    ["Category 3"]: "",
    ["Material 1"]: "",
    ["Material 2"]: "",
    ["Material 3"]: "",
    ["Child"]: "",
    ["Mens"]: "",
    ["Teen"]: "",
    ["Unisex"]: "",
    ["Womens"]: "",
    ["Vendor 1"]: "",
    ["Vendor 2"]: "",
    ["Abc"]: "",
    ["Xyz"]: "",
    ["Tom"]: "",
    ["Gemstone 1"]: "",
    ["Gemstone 2"]: "",
    ["Gemstone 3"]: "",
    ["Collection 1"]: "",
    ["Collection 2"]: "",
    ["Collection 3"]: "",
    ["Collection 4"]: "",
    ["Sales Rep"]: "",
    ["Jewelry Access"]: "",
    ["RingBuilder Access"]: "",
    ["Hide Price"]: "",
    ["Auto Sync"]: "",
    ["Data & Permissions"]: "",
    ["Make Appointment"]: "",
    ["Store Notes"]: "",
    ["Disconnect Retailer"]: "",
    ["Conversation data is compulsory"]: "",
    ["Note Title is compulsory"]: "",
    ["Note Description"]: "",
    ["Appointment data is compulsory"]: "",
    ["Access"]: "",
    ["Avatar"]: "",
    ["Job Title"]: "",
    ["E-Mail"]: "",
    ["Phone No."]: "",
    ["Locations"]: "",
    ["Store Bio"]: "",
    ["Years In Business"]: "",
    ["Create A New Note"]: "",
    ["Edit Note"]: "",
    ["Monday"]: "",
    ["Tuesday"]: "",
    ["December"]: "",
    ["October"]: "",
    ["July"]: "",
    ["Bhavin Patel"]: "",
    ["Started a"]: "",
    ["with your store."]: "",
    ["Create Appointment"]: "",
    ["Calendars"]: "",
    ["Calendar"]: "",
    ["Day"]: "",
    ["Engagement"]: "",
    ["Cleaning"]: "",
    ["Repair"]: "",
    ["Wedding"]: "",
    ["Select Date"]: "",
    ["Select Calendar"]: "",
    ["Select Contact"]: "",
    ["ABC"]: "",
    ["John"]: "",
    ["XYZ"]: "",
    ["User"]: "",
    ["Set Day"]: "",
    ["Thursday"]: "",
    ["Friday"]: "",
    ["Saturday"]: "",
    ["Set Time"]: "",
    ["Enter Name."]: "",
    ["Enter Email Address."]: "",
    ["Enter Friend's Name."]: "",
    ["Enter Friend's Email Address."]: "",
    ["Search all"]: "",
    ["Inventory is compulsory"]: "",
    ["Matched Pair Stock is compulsory"]: "",
    ["Stones are compulsory"]: "",
    ["Size is compulsory"]: "",
    ["Cost is compulsory"]: "",
    ["Kindly select the shape"]: "",
    ["Kindly select the color"]: "",
    ["Kindly select the fancy codes"]: "",
    ["Kindly select the fancy names"]: "",
    ["Other is compulsory"]: "",
    ["Kindly select the clarity"]: "",
    ["Kindly select the cut grade"]: "",
    ["Kindly select the certificate"]: "",
    ["Kindly select the certificate number"]: "",
    ["Depth is compulsory"]: "",
    ["Table is compulsory"]: "",
    ["Measurements are compulsory"]: "",
    ["Kindly select the polish value"]: "",
    ["Kindly select the symmetry"]: "",
    ["Kindly select the fluorescence"]: "",
    ["Kindly select the girdle"]: "",
    ["Kindly select the culet"]: "",
    ["Kindly select the culet condition"]: "",
    ["Crown Angle is compulsory"]: "",
    ["Pavillion Angle is compulsory"]: "",
    ["Comments are compulsory"]: "",
    ["Store Location is compulsory"]: "",
    ["Employee Name is compulsory"]: "",
    ["Employee ID is compulsory"]: "",
    ["Employee ID is Invalid"]: "",
    ["Please select file."]: "",
    ["Option 3"]: "",
    ["Golden"]: "",
    ["FB"]: "",
    ["FBGNY"]: "",
    ["Brown"]: "",
    ["FL"]: "",
    ["VVS1"]: "",
    ["Excellent 1"]: "",
    ["Strong Blue"]: "",
    ["Medium Blue"]: "",
    ["Extremely"]: "",
    ["Do not include pricing informatin in comments."]: "",
    ["Video Url"]: "",
    ["No file chosen"]: "",
    ["Please select delimiter."]: "",
    ["Diamond Import"]: "",
    ["RAPNET"]: "",
    ["IDEX"]: "",
    ["POLYGON"]: "",
    ["DIRECT LINK"]: "",
    ["Use Rapnet Instant Inventory Service."]: "",
    ["Standard Diamond Form"]: "",
    ["Diamond Importing"]: "",
    ["Alphanumeric"]: "",
    ["ProductName1"]: "",
    ["Short title for the product. This will be displayed on product pages. If listing child products, please include color and size in the name to differentiate."]:
      "",
    ["Accessories"]: "",
    ["Tiaras"]: "",
    ["Womans Fashion Bag"]: "",
    ["Atia 81"]: "",
    ["Fashion Earrings"]: "",
    ["Chandelier"]: "",
    ["Charms"]: "",
    ["Beads"]: "",
    ["Bracelets"]: "",
    ["Product Name1.xls"]: "",
    ["Delivery Time"]: "",
    ["hours"]: "",
    ["Featured"]: "",
    ["Product name is compulsory"]: "",
    ["Retail Description is compulsory"]: "",
    ["Retail Price is compulsory"]: "",
    ["Kindly select the Material Type"]: "",
    ["Kindly select the Category"]: "",
    ["Kindly select all the necessary Field from essential"]: "",
    ["Fixed"]: "",
    ["WholeSale"]: "",
    ["Diamond Upload"]: "",
    ["Please select image file only."]: "",
    ["Please select zip file only."]: "",
    ["Please select .csv|xls|txt|json file only."]: "",
    ["Please select currency."]: "",
    ["Please select rounding."]: "",
    ["Please enter default markup."]: "",
    ["Please enter default markup value less then 100%."]: "",
    ["Select Currency"]: "",
    ["Select Rounding"]: "",
    ["Collection name is compulsory"]: "",
    ["Kindly select the Brand Name"]: "",
    ["Need to select atleast one category"]: "",
    ["Overnight Mountings Ring Builder"]: "",
    ["Data Ratings"]: "",
    ["Victor"]: "",
    ["Manage Inventory"]: "",
    ["Manage Location"]: "",
    ["Vendor Type"]: "",
    ["Designer"]: "",
    ["Watch Brand"]: "",
    ["Bridal Designer"]: "",
    ["Connection Status"]: "",
    ["Vendor Profile"]: "",
    ["Why Choose Overnight?"]: "",
    ["Inventory"]: "",
    ["Page"]: "",
    ["of"]: "",
    ["pages"]: "",
    ["per page"]: "",
    ["Product Deactivation Guidelines"]: "",
    ["Activate/Deactivate All"]: "",
    ["Products"]: "",
    ["Jack"]: "",
    ["Lucy"]: "",
    ["One"]: "",
    ["Two"]: "",
    ["Male"]: "",
    ["Female"]: "",
    ["Save Changes"]: "",
    ["Trade Show Appointment"]: "",
    ["Preferred"]: "",
    ["Questions"]: "",
    ["I Am Interested In Placing Your Inventory On My Website."]: "",
    ["Connected"]: "",
    ["Request Update"]: "",
    ["Image Preview"]: "",
    ["By Size"]: "",
    ["By Price"]: "",
    ["United States - Dollar - $"]: "",
    ["Great Britan - Pound - £"]: "",
    ["Australia - Dollar - $"]: "",
    ["size"]: "",
    ["Custom"]: "",
    ["US Dollars"]: "",
    ["Great Britain Pound"]: "",
    ["Apex Diamonds"]: "",
    ["Asscher"]: "",
    ["Cushion"]: "",
    ["Best Diamonds"]: "",
    ["Signature Diamonds"]: "",
    ["Normal Diamonds"]: "",
    ["Diamond Link URL"]: "",
    ["Automatically Accept New Diamond Dealers"]: "",
    ["When a new Diamond Dealer joins GemFind, they will automatically be added to your connected Diamond Dealer List. Toggle this option to 'No', if you would prefer not to have Diamond Dealers automatically added to your account."]:
      "",
    ["Sort Search Results Initially By"]: "",
    ["Change this value to modify the initial sorting field of the search results. Leave blank for default value (none)."]:
      "",
    ["Order Search Results Initially By"]: "",
    ["Change this value to modify the initial sorting order of the initial sorting field. Leave blank for default value (none)."]:
      "",
    ["Show In House Diamonds Column with SKU (for New GF Link Only)"]: "",
    ["Choose whether to display in house diamonds columns in search result. It will also display In House SKU in result pop up and details page."]:
      "",
    ["Show In House Diamonds First"]: "",
    ["Choose whether to display in house diamonds first in the search result."]:
      "",
    ["Show GIA report check link"]: "",
    ["When enabled this will display a link to the GIA diamond cert. on GIA’s website, when disabled it will display a link to the GIA certificate image."]:
      "",
    ["Show Advance options as Default in Diamond Search"]: "",
    ["Show Certificate in Diamond Search"]: "",
    ["Choose whether to display a link to the diamond certificate or not."]: "",
    ["Show EGL Certificate Variants"]: "",
    ["Choose whether to display EGL Certificate Variants or not."]: "",
    ["Show Request Certificate"]: "",
    ["When “Show GIA report check link” is set to NO, this option allows you to choose whether to display the “Request Certificate” link or not. This link will display the GIA cert. image (if available) as opposed to the cert. on GIA’s website, and if “Disable Certificate Links in Diamond Search” is set to YES, this link will pop-up a “Request More Information” form."]:
      "",
    ["Disable Certificate Links in Diamond Search"]: "",
    ["Hide certificates and show only request form."]: "",
    ["Show Color Items out of Range"]: "",
    ["Choose whether the last displayed Diamond Color filter button will also display any remaining color of that type or higher, all the way to ‘Z’."]:
      "",
    ["Show Clarity Items out of Range"]: "",
    ["Choose whether the last displayed Diamond Clarity filter button will also display any remaining clarities of that type or higher, all the way to “I3”."]:
      "",
    ["Accept Total Price for Diamond"]: "",
    ["When uploading diamonds into JewelCloud with a data sheet, the default process to calculate Cost Price is by multiplying CostPerCarat by the Carat value. When this option is set to YES, the prices on the uploaded diamond data sheet will be accepted as total cost price."]:
      "",
    ["Choose whether to display the link which allows the customer the ability to Drop a Hint."]:
      "",
    ["Email A Friend"]: "",
    ["Choose whether to display the link which allows the customer the ability to Email A Friend."]:
      "",
    ["Show Origin On Diamond Search"]: "",
    ["Choose whether to display the “Origin” drop-down menu within the Advanced Search filters. (Ex. All, Canadian, Lab-Created)."]:
      "",
    ["Show vendor comments on details page"]: "",
    ["Choose whether to use show comments made by diamond vendors on the details page for each stone in the diamond search results."]:
      "",
    ["Show Contact Number"]: "",
    ["Choose whether to use show contact Number or not."]: "",
    ["Choose whether to use show addresses or not."]: "",
    ["Alternate diamond details link URL"]: "",
    ["Change this value to alter the actual hyperlink to the diamond details. This could be changed to link to your own diamond details page or your own shopping cart. Additional diamond detail parameters are passed in the URL when this value is present. Leave blank for default value."]:
      "",
    ["Use Custom Detail URL for Diamonds?"]: "",
    ["Choose whether to enable or disable the “Alternate diamond details link URL” option."]:
      "",
    ["Show Diamond Prices"]: "",
    ["You may choose not to show diamond prices in search results if desired. Price range field in search form is not displayed when prices are not displayed."]:
      "",
    ["Markup Your Own Inventory"]: "",
    ["Choose whether to apply markup on diamond inventory uploaded by you."]:
      "",
    ["Show Price Per Carat"]: "",
    ["Choose whether to display the price per carat value on the diamond details page “Specifications” tab."]:
      "",
    ["Show Instagram Share"]: "",
    ["Choose whether to display Instagram Share or not."]: "",
    ["Call to Action Button"]: "",

    ["AddToCart Button"]: "",
    ["Schedule a Viewing"]: "",
    ["Alternate Shopping Cart URL"]: "",

    ["Show AddToCart Button On Search Page"]: "",
    ["Choose whether to display the “Add to Cart” button on Search Page or not."]:
      "",
    ["Request Diamond"]: "",
    ["Compare Diamond"]: "",
    ["Select Option"]: "",
    ["Ring Builder Version I"]: "",
    ["Ring Builder Version II"]: "",
    ["Ring Builder Version III"]: "",
    ["Ring Builder Version IV"]: "",
    ["Ring Builder Version V"]: "",
    ["Earrings"]: "",
    ["18k Yellow Gold"]: "",
    ["18k Rose Gold"]: "",
    ["AUD"]: "",
    ["GBP (Pound)"]: "",
    ["USD"]: "",
    ["Items View"]: "",
    ["List View"]: "",
    ["We offer over 50,000 mountings and Semi-mounts to choose from. Overnight has one of the largest"]:
      "",
    ["In Stock"]: "",
    ["inventories in the industry. All of our"]: "",
    ["mountings and Semi-mounts can be returned within 10 business days for a full refund. Our entire line is offered in Sterling, 10k, 14k, 18k, 950 Platinum and 950 Palladium. All gold mountings and semi mounts can be ordered in colors White, Yellow or Pink. Castings available within 3 business days, out of stock Polished mountings available within 3-5 business days, and semi mount and finished goods are available within 5-7 business days. Any one of our 50,000+ styles can be ordered as"]:
      "",
    ["Authentix"]: "",
    ["Samples. Any style can be modified in any way to meet your customers needs. Overnight's talented"]:
      "",
    ["custom crafted department"]: "",
    ["can create anything as a new model using CAD and Hand Carved. We employ some of the most talented setters in the industry that can set your stone(s). We offer 7 different diamond qualities including black diamonds and Champagne diamonds. Our customer service department is extremely knowledgeable in all aspects of jewelry and will assist you in a friendly and professional manner. Overnight's product development team is always creating new styles from basics to cutting edge fashion designs ensuring that you will find what you are looking for. All of our Mountings are proudly manufactured in NY and go through a stringent quality control process before leaving our facility, ensuring that you get a great product in a timely manner. Overnight reserves the right to refuse or recall any product information, images or content being used on customer websites and catalogs"]:
      "",
    ["Please Select Manufacturer"]: "",
    ["Please select markup"]: "",
    ["Please Enter Min Range"]: "",
    ["Please Enter Max Range"]: "",
    ["Please Enter Markup"]: "",
    ["Malaysia - Riggit - $"]: "",
    ["New Zealand - Dollar - $"]: "",
    ["Canadian - Dollar - $"]: "",
    ["Singapore - Dollar - S$"]: "",
    ["Chinese - Yuan - ¥"]: "",
    ["Korean - Won - ₩"]: "",
    ["Denmark - Danish Krone - kr."]: "",
    ["South African - Rand - R"]: "",
    ["Europe - Euro - €"]: "",
    ["Swiss - Franc - CHf"]: "",
    ["Swedish Krona - Sweden - SEK"]: "",
    ["Diamond Link Shopping Cart Configuration Instructions"]: "",
    ["We will be sending parameters to the page on your website as below url"]:
      "",
    ["Example Url"]: "",
    ["To Get Diamond Detail"]: "",
    ["Click Here to access API to get diamond detail and checkout how it works online."]:
      "",
    ["to access API to get diamond detail and checkout how it works online."]:
      "",
    ["Click Here"]: "",
    ["Below is the list of parameters with respective possible values which can be supplied to each parameter."]:
      "",
    ["DiamondId"]: "",
    ["Pass diamond number of diamond. (you will get this as DiamondID on query string of the url as shown above)"]:
      "",
    ["Pass GemFind # which is assigned to your account. For Example, 720 for GemFind."]:
      "",
    ["You will get XML formatted response from the API response. You will need to parse and use that data to create diamond as product on your website."]:
      "",
    ["DealerLink"]: "",
    ["Ring Builder Shopping cart configuration instructions"]: "",
    ["To Get Setting Detail"]: "",
    ["to access API to get setting detail and checkout how it works online."]:
      "",
    ["SettingId"]: "",
    ["Pass setting number of setting. (SettingID from the query string parameter as shown on example url)"]:
      "",
    ["Masterlink Shopping cart integration instructions"]: "",
    ["To Get Product Detail"]: "",
    ["You can integrate your own shopping cart with gemfind designers products via API. You can use this API such that gemfind add to cart button will add product in your website's shopping cart."]:
      "",
    ["JewelryID"]: "",
    ["Pass JewelryID # for which you want to get data."]: "",
    ["Stud Builder Shopping cart integration instructions"]: "",
    ["There will be two diamond id passed comma separated so you will need to get diamond details twice once for each diamond as stud has pair of stones"]:
      "",
    ["To Get Stud Mounting Detail"]: "",
    ["to access API to get Stud Mounting detail and checkout how it works online."]:
      "",
    ["Pass JewelryID # for which you want to get data. (DesignerProductID from the query string parameter as shown on example url)"]:
      "",
    ["Jewelry Category"]: "",
    ["Designer Category"]: "",
    ["Bridal Category"]: "",
    ["SKU Prefix"]: "",
    ["Category Mapping"]: "",
    ["Column Mapping"]: "",
    ["GemFind Category"]: "",
    ["Website Category"]: "",
    ["Dealer Stock Number"]: "",
    ["Product Description"]: "",
    ["Designers Mapping"]: "",
    ["GemFind Designers"]: "",
    ["Bangles"]: "",
    ["Big & important Bracelets"]: "",
    ["Chain Bracelets"]: "",
    ["Necklaces"]: "",
    ["Chains"]: "",
    ["Sync Products To Your Website"]: "",
    ["Completed"]: "",
    ["Sync Products"]: "",
    ["Force Full Sync"]: "",
    ["Detail"]: "",
    ["Total Products Processed"]: "",
    ["Out Going Data Map"]: "",
    ["API Url"]: "",
    ["Enable Sync"]: "",
    ["Attrubute Mapping"]: "",
    ["Create XML"]: "",
    ["Notifications"]: "",
    ["registered with the website"]: "",
    ["Jewelry vendors"]: "",
    ["Active Jewelry"]: "",
    ["Diamond Vendors"]: "",
    ["Active Diamonds"]: "",
    ["Contact Us"]: "",
    ["Favorites"]: "",
    ["New & Recently Updated Vendors"]: "",
    ["View More"]: "",
    ["Quick Links"]: "",
    ["View My Retailers"]: "",
    ["Update Inventory"]: "",
    ["Invite A Retailer"]: "",
    ["Manage Shows"]: "",
    ["Ratings"]: "",
    ["The Star rating is based off of 7 key areas. Images,Product Name, Product Description, Categories/Collections, Attributes, Pricing and Last Updated."]:
      "",
    ["For details on how you can improve your score, please click here to view this document."]:
      "",
    ["Import Your Data"]: "",
    ["Upload Your Jewelry & Diamonds"]: "",
    ["Import Diamonds"]: "",
    ["Upload Diamond"]: "",
    ["Import Jewelry"]: "",
    ["Upload Jewelry"]: "",
    ["Video/Images"]: "",
    ["Email"]: "",
    ["Friend's Name"]: "",
    ["Friend's Email"]: "",
    ["Buyer Name is compulsory"]: "",
    ["Book Appointment"]: "",
    ["Appointment Description"]: "",
    ["Gemfind"]: "",
    ["Your recipient will recieve the details on your selected diamonds"]: "",
    ["Message"]: "",
    ["Please select video file only."]: "",
    ["Is Lab Created"]: "",
    ["Please Enter Link"]: "",
    ["Please Enter Text"]: "",
    ["View /Edit Mapping Columns"]: "",
    ["Preparing Your Data"]: "",
    ["Resubmit"]: "",
    ["Re-Activate"]: "",
    ["Fancy Color Diamond Link Emails"]: "",
    ["Diamond Link Emails"]: "",
    ["Time pieces"]: "",
    ["Click Here for GFLINK ECommerce Link Administration"]: "",
    ["View/Update Your Online Dealer Inventory List"]: "",
    ["Change Inventory Mark-Up Values for Markup BY PRICE"]: "",
    ["Update Search Page Results Appearance"]: "",
    ["Update Search Page Results Preferences"]: "",
    ["For Advanced Users"]: "",
    ["Change Your Shipping Charge"]: "",
    ["Your GFLINK Addresses are"]: "",
    ["ADMIN NOTE"]: "",
    ["Your GFLink addresses MUST contain the correct domain name, http://platform.stage.jewelcloud.com. Using http://platform.stage.jewelcloud.com or any variation of gemfind.com is NOT correct and no longer functions. Please make sure your GFWatchLink URL is correct. Use the URLs below. The links below display your inventory"]:
      "",
    ["Marked Up"]: "",
    ["a % determined by the Watch'S PRICE."]: "",
    ["Search Only"]: "",
    ["Watch Configurator"]: "",
    ["Procedure to implement Watch Configurator: To integrate the Watch Configurator directly into one of your web pages on your website, you will need to follow the general procedure below. This procedure will vary depending upon the technology your website uses, and your web developer will need to make adjustments accordingly. This will require custom website development work on your end. You basically have two choices when it comes to seamlessly including Watch Configurator in your website: FRAMEs or IFRAMEs."]:
      "",
    ["FRAMEs: If your site uses FRAMEs in a FRAMESET, then the Watch Configurator may easily be included as one of the FRAME contents. This is one of the simplest methods of embedding results, however it is not as attractive as the other methods and may not be a feasible option for you if you do not already use FRAMEs. Scrollbars may be prgrammatically eliminated for a cleaner look."]:
      "",
    ["IFRAMEs / LAYERs: Define a floating IFRAME (include LAYER coding to accomodate Netscape) and place it on your page where you want the Watch Configurator to apear. This will look better than a FRAME defined as part of a FRAMESET. Some attention to the size of the IFRAME must be paid. The IFRAME window is not programmatically 'resizable' once it has been defined on a page, therefore you must create the IFRAME with a HEIGHT and WIDTH that will accommodate the entire contents of the Watch Configurator. The number of results/rows returned by the Watch Configurator is constant and will ensure a constant height (and fit) for your IFRAME window."]:
      "",
    ["Search by GEMFIND INVENTORY ID #"]: "",
    ["Enter GEMFIND ID # and CLICK 'GO'"]: "",
    ["Go"]: "",
    ["Search by Dealer's Inventory #"]: "",
    ["Enter Dealer Inventory # and CLICK 'GO'"]: "",
    ["A & M Diamonds"]: "",
    ["A & W Gems"]: "",
    ["A & F Gems"]: "",
    ["Pendant Builder Shopping cart integration instructions"]: "",
    ["To Get Pendant Mounting Detail"]: "",
    ["Please Enter Password."]: "",
    ["Password Must contains Minimum 6 characters, at least one uppercase letter, one number and one special character"]:
      "",
    ["Please Enter Confirm Password."]: "",
    ["Password And Confirm Password Do Not Match."]: "",
    ["Server Up-Time"]: "",
    ["Uptime info not available - please try reloading this page"]: "",
    ["Select Client Name"]: "",
    ["Active - No Profile"]: "",
    ["Select Type"]: "",
    ["Select Retailer Status"]: "",
    ["API Url is compulsory"]: "",
    ["API Key is compulsory"]: "",
    ["Provide Proper DealerID"]: "",
    ["account ?"]: "",
    ["You have select"]: "",
    ["Please select .png file only."]: "",
    ["Please Select Correct Image Size."]: "",
    ["All Dealer"]: "",
    ["Please Enter Only Alphabet."]: "",
    ["Please Select Only Images."]: "",
    ["API Access Token"]: "",
    ["Set Initial Filter Settings"]: "",
    ["Set Your Available Diamonds"]: "",
    ["This will control default diamond filters when users initially visit your DiamondLink."]:
      "",
    ["This will control which diamonds are available in your DiamondLink."]: "",
    ["Lab Setting"]: "",
    ["Mined Setting"]: "",
    ["Ct."]: "",
    ["Please select zip file only were in Compress images together & upload in a .zip format."]:
      "",
    ["Password does not match"]: "",
    ["Name is compulsory"]: "",
    ["Name is invalid"]: "",
    ["Email is compulsory"]: "",
    ["Confirm Password is compulsory"]: "",
    ["Logins"]: "",
    ["User Role Detail"]: "",
    ["New User"]: "",
    ["Add New User"]: "",
    ["Access Level"]: "",
    ["Edit User"]: "",
    ["Delete User"]: "",
    ["Last Login"]: "",
    ["User Since"]: "",
    ["Select Account type"]: "",
    ["Company 1"]: "",
    ["Company 2"]: "",
    ["Company 3"]: "",
    ["Apply"]: "",
    ["Language Selection for Platform and Tools"]: "",
    ["Default Language Is"]: "",
    ["Select Language"]: "",
    ["Currency Selection for Platform and Tools"]: "",
    ["Default Currency Is"]: "",
    ["Please select Image File Only"]: "",
    ["Email Address is compulsory"]: "",
    ["Email Address is Invalid"]: "",
    ["Image Name"]: "",
    ["Company Detail"]: "",
    ["Web Site"]: "",
    ["Company Bio"]: "",
    ["Update Profile"]: "",
    ["Advertising Samples"]: "",
    ["Social Integrations Settings"]: "",
    ["Website's Facebook App ID Setup"]: "",
    ["Masterlink Facebook App Setup"]: "",
    ["MasterLink Facebook canvas App ID"]: "",
    ["MasterLink Facebook canvas App URL"]: "",
    ["RingBuilder Facebook App Setup"]: "",
    ["RingBuilder Facebook canvas App ID"]: "",
    ["RingBuilder Facebook canvas App URL"]: "",
    ["StudBuilder Facebook App Setup"]: "",
    ["StudBuilder Facebook canvas App ID"]: "",
    ["StudBuilder Facebook canvas App URL"]: "",
    ["PendantBuilder Facebook App Setup"]: "",
    ["PendantBuilder Facebook canvas App ID"]: "",
    ["PendantBuilder Facebook canvas App URL"]: "",
    ["Diamondlink Facebook App Setup"]: "",
    ["DiamondLink Facebook canvas App ID"]: "",
    ["DiamondLink Facebook canvas App URL"]: "",
    ["Basic Information"]: "",
    ["Notiﬁcation Settings"]: "",
    ["Choose what you want to be notiﬁed about and where."]: "",
    ["Inquiries"]: "",
    ["New Favorites List"]: "",
    ["New Favorites"]: "",
    ["Vendor Requests"]: "",
    ["Send Upload Report"]: "",
    ["Purchase Orders"]: "",
    ["Sales Orders"]: "",
    ["Ignore blank uploads"]: "",
    ["SMTP Settings"]: "",
    ["Send e-mails from your own address."]: "",
    ["Mail Server"]: "",
    ["Port Number"]: "",
    ["Server Requires Authentication"]: "",
    ["Use SSL"]: "",
    ["Test Your SMTP Settings"]: "",
    ["TEST"]: "",
    ["Add Map Here"]: "",
    ["Location 1"]: "",
    ["Address"]: "",
    ["Zip"]: "",
    ["Location E-Mail"]: "",
    ["Location Phone"]: "",
    ["Update Location"]: "",
    ["Hours of Operation"]: "",
    ["Sun"]: "",
    ["Mon"]: "",
    ["Tues"]: "",
    ["Wed"]: "",
    ["Thu"]: "",
    ["Fri"]: "",
    ["Sat"]: "",
    ["Location QR Code"]: "",
    ["QR Code Generator"]: "",
    ["View Inventory"]: "",
    ["Please Enter Min Price."]: "",
    ["Please Enter Max Price."]: "",
    ["Please Enter Markup."]: "",
    ["Confirmation"]: "",
    ["Scheduled Reminder"]: "",
    ["Tier Name is Compulsory"]: "",
    ["Tier Value is Compulsory"]: "",
    ["First Tier Value is Compulsory"]: "",
    ["Selecting Any Field is compulsory"]: "",
    ["Tier Type"]: "",
    ["Tier One"]: "",
    ["Tier Two"]: "",
    ["Enter Tier Name"]: "",
    ["View Location"]: "",
    ["Country is compulsory"]: "",
    ["Country is invalid"]: "",
    ["Contact name is invalid"]: "",
    ["Add to Cart Report"]: "",
    ["Wish List Report"]: "",
    ["Please Enter Message."]: "",
    ["View My"]: "",
    ["Attribute is compulsory"]: "",
    ["Please Select csv file only"]: "",
    ["Reset Mapping"]: "",
    ["Vary Pricing By Finger Size"]: "",
    ["Schedule Reminder"]: "",
    ["Select Finger Size"]: "",
    ["Video Type"]: "",
    ["This option will override the data."]: "",
    ["Load Image From URL"]: "",
    ["Trade show is compulsory"]: "",
    ["Sales rep is compulsory"]: "",
    ["Appointment date is compulsory"]: "",
    ["Buyer name is compulsory"]: "",
    ["Question is compulsory"]: "",
    ["Time is compulsory"]: "",
    ["Write the first message ...."]: "",
    ["Post"]: "",
    ["Select Sales Rep"]: "",
    ["Location Details"]: "",
    ["Location Email"]: "",
    ["Sunday"]: "",
    ["AM to"]: "",
    ["Wednesday"]: "",
    ["No record found"]: "",
    ["Select 1 Tier PageName"]: "",
    ["Select 2 Tier PageName"]: "",
    ["Select Location"]: "",
    ["Use"]: "",
    ["Select File"]: "",
    ["Upload Images"]: "",
    ["Use this Company Name, Tagline and bio as my Brand name, tagline and Bio on my authorized Retailer websites."]:
      "",
    ["Click here to configure your different Brand marketing message for use on your authorized Retailer websites."]:
      "",
    ["Since"]: "",
    ["Primary E-mail"]: "",
    ["Primary Phone"]: "",
    ["Primary Contact Name"]: "",
    ["Company Logo"]: "",
    ["Phone no is invalid"]: "",
    ["Change"]: "",
    ["Product Details"]: "",
    ["Jewelery Characteristics"]: "",
    ["SKU #"]: "",
    ["Style"]: "",
    ["Cost ($)"]: "",
    ["MSRP ($)"]: "",
    ["Width (mm)"]: "",
    ["Thickness (mm)"]: "",
    ["Length (in)"]: "",
    ["Weight (gm)"]: "",
    ["Last Updated On"]: "",
    ["Description"]: "",
    ["Dealer Info"]: "",
    ["Dealer Information"]: "",
    ["Company"]: "",
    ["Contact Person"]: "",
    ["Fax"]: "",
    ["E-mail"]: "",
    ["Export All"]: "",
    ["Lab Grown Diamond"]: "",
    ["Warning: Changing or modifying your"]: "",
    ["will cause the"]: "",
    ["to be reset: Click Yes to proceed"]: "",
    ["In Frame"]: "",
  },
};
